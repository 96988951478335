import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeVarsAPACVws, buildThemeMixinsAPACVws, buildThemeWidgetStylesAPACVws } from 'dw-apac-vws/theme';

const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => merge({}, buildThemeVarsAPACVws(vars)),
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => buildThemeMixinsAPACVws(vars),
    (vars: Omit<Theme, 'widgets'>) => buildThemeWidgetStylesAPACVws(vars),
  );

export default createTheme;
